import {CommonButton} from '../../../../common/CommonButton/CommonButton';
import PrevIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import {Typography} from '@mui/material';
import NextIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import {memo} from 'react';
import {LessonHeaderContainer, styles} from './styles';

interface Props {
    onNext: () => void;
    onPrev: () => void;

    isNextDisabled?: boolean;
    isPrevDisabled?: boolean;
}

export const LessonHeader = memo<Props>((props) => (
    <LessonHeaderContainer>
        <CommonButton startIcon={<PrevIcon sx={styles.buttonIcon}/>}
                      sx={styles.videoButton}
                      onClick={props.onPrev}
                      disabled={props.isPrevDisabled}>
            לסרטון הקודם
        </CommonButton>
        <Typography variant={'h3'} sx={styles.title}>Javascript</Typography>
        <CommonButton endIcon={<NextIcon sx={styles.buttonIcon}/>}
                      sx={styles.videoButton}
                      onClick={props.onNext}
                      disabled={props.isNextDisabled}>
            לסרטון הבא
        </CommonButton>
    </LessonHeaderContainer>
))
