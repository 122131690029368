import {styled, SxProps, Theme} from '@mui/material';

const MyCoursesContainer = styled('section')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: '8vh 6vw 8vh 6vw',
    backgroundColor: theme.palette.primary.dark,
    margin: '20vh 0',
}));

const MyCoursesList = styled('section')(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: '5vh',
    rowGap: '2rem',
    columnGap: '2rem',
    justifyContent: 'center',
    overflow: 'hidden',
    padding: '5vh',
    boxSizing: 'content-box',

    [`${theme.breakpoints.down('md')}`]: {
        flexDirection: 'column',
        flexWrap: 'no-wrap',
    },
}));

const CourseCard = styled('div')(({theme}) => ({
    padding: '0.75rem',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    transition: 'all .2s ease-out',
    color: theme.palette.text.primary,

    '&:hover': {
        color: theme.palette.secondary.main,
        transform: 'scale(1.05)',
        cursor: 'pointer',
        boxShadow: `0 0 8px ${theme.palette.secondary.main}`,
    },
}));

const styles: Record<string, SxProps<Theme>> = {
    title: (theme) => ({
        color: theme.palette.text.primary,
        fontWeight: 'bold',
    }),
    image: {
        padding: '2vh',
        borderRadius: '8px',
        width: '10rem',
        height: '10rem',
    },
    courseName: {
        flex: 1,
        fontWeight: 'bold',
    },
};

export {
    MyCoursesContainer,
    MyCoursesList,
    CourseCard,
    styles,
};
