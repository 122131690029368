import {styled, SxProps, Theme} from '@mui/material';

const LessonBriefContainer = styled('div')(({theme}) => ({
    display: 'flex',
    direction: 'ltr',
    margin: '0 0.2rem',
    padding: '0.25rem',
    boxSizing: 'border-box',
    borderBottom: 'solid 1px rgba(255,255,255, 0.8)',
    columnGap: '0.2rem',

    [`${theme.breakpoints.down('md')}`]: {
        padding: '0.25rem 0',
        margin: 0,
    },
}));

const LessonBriefHover = styled('div')({
    display: 'flex',
    flex: 1,
    paddingRight: '0.5rem',

    '&:hover': {
        backgroundColor: 'rgba(255,255,255,0.2)',
        borderRadius: '8px',
    },
})

const LessonBriefContentContainer = styled('section')({
    flex: 1,
    padding: '0.25rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignSelf: 'start',
    cursor: 'pointer',
    rowGap: '0.5rem',
});

const TimeContainer = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.main,
    columnGap: '0.2rem',
}));

const styles: Record<string, SxProps<Theme>> = {
    checkbox: (theme) => ({
        justifySelf: 'flex-end',
        color: theme.palette.text.primary,

        '&.Mui-checked': {
            color: theme.palette.text.primary,
        },

        '&: hover': {
            color: theme.palette.secondary.main,
        },
    }),
    lessonName: (theme) => ({
        fontSize: '1rem',
        fontWeight: 'bold',
        lineHeight: 1.5,
        color: theme.palette.text.primary,
        overflow: 'hidden',
    }),
    lessonTime: (theme) => ({
        color: theme.palette.secondary.main,
        fontSize: '0.9rem',
    }),
    playIcon: (theme) => ({
        fontSize: '2rem',
        color: theme.palette.text.primary,
        alignSelf: 'center',
        cursor: 'pointer',
    }),
    editIcon: (theme) => ({
       fontSize: '1.5rem',
       color: theme.palette.text.primary,
       alignSelf: 'center',
       cursor: 'pointer',

       '&:hover': {
          color: theme.palette.secondary.main,
       } ,
    }),
    clockIcon: {
        fontSize: '1rem',
    },
};

export {
    LessonBriefContainer,
    LessonBriefHover,
    LessonBriefContentContainer,
    TimeContainer,
    styles,
};
