import {styled, SxProps, Theme} from '@mui/material';

const EditLessonContainer = styled('div')(({theme}) => ({
    width: '30vw',
    padding: '4vh 0',
    backgroundColor: theme.palette.primary.dark,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

    [`${theme.breakpoints.down('md')}`]: {
        width: '90vw',
    },
}));

const styles: Record<string, SxProps<Theme>> = {
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: (theme) => ({
        color: theme.palette.text.primary,
        fontWeight: 'bold',
        marginBottom: '1.5rem',
    }),
    inputLabel: (theme) => ({
        color: theme.palette.secondary.main,
    }),
    input: {
        fontSize: '1rem',
        direction: 'ltr',
    },
    saveButton: {
        marginTop: '2rem',
    },
};

export {
    EditLessonContainer,
    styles,
};
