import {styled, SxProps, Theme} from '@mui/material';

const CreateUserFormContainer = styled('section')({
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    direction: 'ltr',
});

const FormWrapper = styled('div')({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    rowGap: '3vh',
    boxSizing: 'border-box',
    padding: '0 15%',
});

const FullNameContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

const InputsContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: '0.5rem',
});

const styles: Record<string, SxProps<Theme>> = {
    titleStyle: (theme) => ({
        textAlign: 'center',
        color: theme.palette.text.primary,
        fontWeight: 'bold',
    }),
    subtitleStyle: (theme) => ({
        marginBottom: '2vh',
        color: theme.palette.text.primary,
        fontWeight: 'bold',
    }),
    labelStyle: (theme) => ({
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.main,
        padding: '0 0.25rem',

        '&.Mui-focused': {
            color: theme.palette.text.primary,
        },

        '&.Mui-disabled': {
            color: theme.palette.primary.main,
        },
    }),
    inputStyle: (theme) => ({
        '.MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.text.primary,
        },

        '&.Mui-focused': {
            '.MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.text.primary,
            },
        },
    }),
    rowedInput: ({
        flex: 1,
    }),
    selectFormControlStyle: (theme) => ({
        flex: 1,

        '& .MuiList-root': {
            backgroundColor: theme.palette.primary.main,
        },
    }),
    selectStyle: (theme) => ({
        '.MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.text.primary,
        },

        '.MuiSelect-icon': {
            color: theme.palette.text.primary,
        },

        '&.Mui-focused': {
            '.MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.text.primary,
            },
        },
    }),
    menuListStyle: (theme) => ({
        backgroundColor: theme.palette.primary.main,
        border: 'solid 1px ' + theme.palette.text.primary,
    }),
    menuItemText: (theme) => ({
        color: theme.palette.text.primary,
        textAlign: 'center',
    }),
    menuItemStyle: (theme) => ({
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.main,

        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
        },

        '&.Mui-selected': {
            backgroundColor: theme.palette.secondary.main,

            '&:hover': {
                backgroundColor: theme.palette.secondary.main,
            },
        },
    }),
};

export {
    styles,
    CreateUserFormContainer,
    FormWrapper,
    FullNameContainer,
    InputsContainer,
};
