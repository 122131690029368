import {styled} from '@mui/material';

const VideoContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '2vh',
});

const Video = styled('video')(({theme}) => ({
    display: 'flex',
    objectFit: 'cover',
    backgroundColor: 'white',

    height: '80vh',
    width: '65vw',

    [`${theme.breakpoints.down('md')}`]: {
        width: '100%',
        height: '50vw',
    },

    [`${theme.breakpoints.down('md')}  and (orientation: landscape)`]: {
        width: '100%',
        height: '90vh',
    },
}));

export {
    VideoContainer,
    Video,
};
