import {LoginFormValues} from '../screens/LoginScreen/LoginForm/LoginForm';
import {axiosInstance} from './httpService';
import {User, UserRole} from '../context/user/userContext';
import {CreateUserFormValues} from '../screens/UserManagement/CreateUser/CreateUserForm/CreateUserForm';
import * as CryptoJS from 'crypto-js';

enum UserErrMsg {
    EMAIL_ERR_MSG = 'המייל שהוזן אינו תקין',
    PASSWORD_ERR_MSG = 'יש להזין סיסמה',
    FIRST_NAME_ERR_MSG = 'לא הוזן שם פרטי',
    LAST_NAME_ERR_MSG = 'לא הוזן שם משפחה',
    PHONE_NUMBER_ERR_MSG = 'המספר טלפון אינו תקין',
}

interface UserDetails {
    firstName: string;
    lastName: string;
    phoneNumber: string;
}

interface UserToServer {
    email: string;
    password: string;
    role: UserRole;
    userDetails: UserDetails;
}

const USER_LOCAL_STORAGE_KEY = 'user';

class UserService {
    private static readonly USER_SECRET = 'g6@DiDkUN8dCZ%&ocj3m5Xi^Lgt!xe';

    public login = (credentials: LoginFormValues) => axiosInstance.post<User>('/auth', credentials);

    public createUser = (user: CreateUserFormValues) => axiosInstance.post(
        '/users',
        this._convertUserFormValuesToServer(user),
    );

    public saveUserInLocalStorage = (user: User) => {
        const encryptedUserData = CryptoJS.AES.encrypt(JSON.stringify(user), UserService.USER_SECRET).toString();

        localStorage.setItem(USER_LOCAL_STORAGE_KEY, encryptedUserData);
    }

    public getUserFromLocalStorage = () => {
        const encryptedUser = localStorage.getItem(USER_LOCAL_STORAGE_KEY);

        if (encryptedUser) {
            const decryptedBytes = CryptoJS.AES.decrypt(encryptedUser, UserService.USER_SECRET);

            return JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
        }
    }

    private _convertUserFormValuesToServer = (userFormValues: CreateUserFormValues): UserToServer => ({
        email: userFormValues.email,
        password: userFormValues.phoneNumber,
        role: userFormValues.role,
        userDetails: {
            firstName: userFormValues.firstName,
            lastName: userFormValues.lastName,
            phoneNumber: userFormValues.phoneNumber,
        },
    });
}

const UserServiceInstance = new UserService();

export {
    UserErrMsg,
    UserServiceInstance as UserService,
    USER_LOCAL_STORAGE_KEY,
};
