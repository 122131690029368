import {FC, useEffect, useState} from 'react';
import {UserRole, useUser} from '../../context/user/userContext';
import {useNavigate} from 'react-router-dom';
import {UserService} from '../../services/userService';

const withRoleGuard = <P extends object>(Component: FC<P>, authorizedRoles?: UserRole[]) => (props: P) => {
    const [isAuthorized, setIsAuthorized] = useState(false);
    const navigate = useNavigate();
    const {user, setUser} = useUser();

    useEffect(() => {
        const userData = user ?? UserService.getUserFromLocalStorage();

        if (userData) {
            setUser(userData);

            if (!!authorizedRoles && !authorizedRoles.includes(userData.role)) {
                navigate('/my-courses');
            } else {
                setIsAuthorized(true);
            }
        } else {
            navigate('/login');
        }
    }, []);

    return isAuthorized ? <Component {...props}/> : <></>;
};

export {
    withRoleGuard,
};
