import {Button, ButtonProps, SxProps, Theme} from '@mui/material';
import {Oval} from 'react-loader-spinner';
import {FC, ReactNode} from 'react';
import {spreadSx} from '../../utils/muiUtils';

type Props = {
    isLoading?: boolean;
    children?: ReactNode;
} & ButtonProps;

const buttonStyle: SxProps<Theme> = (theme) => ({
    mt: 2,
    width: '50%',
    minHeight: '2.4rem',
    alignSelf: 'center',
    color: theme.palette.text.primary,
    bgcolor: theme.palette.secondary.main,
    '&:hover': {
        bgcolor: theme.palette.text.primary,
        color: theme.palette.secondary.main,
    },
    '&.Mui-disabled': {
        bgcolor: '#303742',
    },
});

const CommonButton: FC<Props> = ({isLoading, children, sx, ...buttonProps}) => (
    <Button sx={spreadSx(buttonStyle, sx)}
            type={'submit'}
            disabled={isLoading}
            {...buttonProps}>
        {
            isLoading ?
                <Oval visible
                      height={'1rem'}
                      width={'1rem'}
                      color="#FFF"
                      ariaLabel="oval-loading"
                      secondaryColor="#FFF"
                      strokeWidth={2}
                      strokeWidthSecondary={2}/> :
                children
        }
    </Button>
);

export {CommonButton};
