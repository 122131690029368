import {Navigate, RouteObject} from 'react-router-dom';
import {LoginScreen} from '../screens/LoginScreen/LoginScreen';
import {UserManagement} from '../screens/UserManagement/UserManagement';
import {MyCoursesScreen} from '../screens/MyCoursesScreen/MyCoursesScreen';
import {LessonScreen} from '../screens/LessonScreen/LessonScreen';
import {UserRole} from '../context/user/userContext';
import {USER_MANAGEMENT_ROLES} from './config';

interface NavbarItem {
    name: string;
    link: string;
    permittedRoles?: UserRole[];
}

const NAVBAR_ITEMS: NavbarItem[] = [
    {
        name: 'הקורסים שלי',
        link: '/my-courses',
    },
    {
        name: 'ניהול משתמשים',
        link: '/user-management',
        permittedRoles: USER_MANAGEMENT_ROLES,
    },
];

const ROUTES: RouteObject[] = [
    {
        path: 'lessons',
        element: <LessonScreen/>,
        children: [{
            path: 'episode/:episode',
            element: <LessonScreen/>,
        }],
    },
    {
        path: 'user-management',
        element: <UserManagement/>,
    },
    {
        path: 'login',
        element: <LoginScreen/>,
    },
    {
        path: 'my-courses',
        element: <MyCoursesScreen/>,
    },
    {
        path: '*',
        element: <Navigate to={'/my-courses'}/>,
    },
];

export {
    ROUTES,
    NAVBAR_ITEMS,
};
