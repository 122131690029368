import {SxProps, TextField, TextFieldProps, Theme} from '@mui/material';
import {FC} from 'react';

const inputStyle: SxProps<Theme> = (theme) => ({
    color: theme.palette.text.primary,
});

const inputLabelStyle: SxProps<Theme> = (theme) => ({
    color: theme.palette.text.primary,
});

const CommonInput: FC<TextFieldProps> = (props) => (
    <TextField variant={'standard'}
               InputProps={{sx: inputStyle, dir: 'ltr'}}
               InputLabelProps={{sx: inputLabelStyle}}
               {...props}/>
);

export {CommonInput};
