import {styled} from '@mui/material';

const LessonContentContainer = styled('section')({
    display: 'flex',
    padding: '0 4vw',
    flexDirection: 'column',
    rowGap: '2vh',
});

const VideoAndLessonsContainer = styled('div')(({theme}) => ({
    display: 'flex',
    marginBottom: '3vh',

    [`${theme.breakpoints.down('md')}`]: {
        flexDirection: 'column',
    },
}));

const VideoAndAttachmentsContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

export {
    LessonContentContainer,
    VideoAndLessonsContainer,
    VideoAndAttachmentsContainer,
};
