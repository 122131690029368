import {memo, useRef} from 'react';
import {LoginButton, NavbarContainer, styles} from './styles';
import {ReactComponent as HackcodeLogo} from '../../assets/images/hackode-icon-blue.svg';
import {SvgIcon, Typography} from '@mui/material';
import {NavLink, useNavigate} from 'react-router-dom';
import {NAVBAR_ITEMS} from '../../shared/routes';
import '../../App.css';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import {UserRole, useUser} from '../../context/user/userContext';

interface NavItemProps {
    link: string;
    label: string;
}

const NavItem = memo<NavItemProps>((props) => (
    <NavLink to={props.link} className={'nav-item'}>
        {({isActive}) => (
            <Typography
                sx={isActive ? styles.activeNavButton : styles.navButton}>{props.label}</Typography>
        )}
    </NavLink>
));

const getFilteredItems = (role?: UserRole) => (
    NAVBAR_ITEMS.filter((navItem) => !navItem.permittedRoles?.length || (role && navItem.permittedRoles.includes(role)))
);

const Navbar = memo(() => {
    const navigate = useNavigate();
    const {user, setUser} = useUser();
    const navItems = useRef(getFilteredItems(user?.role));

    const handleLogoClick = () => {
        navigate('/my-courses');
    };

    const handleLogout = () => {
        setUser(undefined);
        navigate('/login');
    };

    return (
        <NavbarContainer>
            <SvgIcon sx={styles.hackcodeLogo} onClick={handleLogoClick}><HackcodeLogo/></SvgIcon>

            {
                navItems.current.map((item) => (
                    <NavItem key={item.link} link={item.link} label={item.name}/>
                ))
            }

            {
                user ?
                    <LoginButton onClick={handleLogout}>
                        <Typography sx={styles.loginText}>יציאה</Typography>
                        <LogoutIcon/>
                    </LoginButton> :
                    <NavLink to={'/login'} className={'nav-item'} style={{marginRight: 'auto'}}>
                        <LoginButton>
                            <Typography sx={styles.loginText}>כניסה</Typography>
                            <LoginIcon/>
                        </LoginButton>
                    </NavLink>
            }
        </NavbarContainer>
    );
});

export {Navbar};
