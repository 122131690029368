import {styled, SxProps, Theme} from '@mui/material';

const AttachmentsContainer = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    direction: 'ltr',
    padding: '1rem',
    rowGap: '1.5rem',

    [`${theme.breakpoints.down('md')}`]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
}));

const ChipContainer = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    columnGap: '0.25rem',

    [`${theme.breakpoints.down('md')}`]: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
}));

const AttachmentsList = styled('div')(({theme}) => ({
    display: 'flex',
    [`${theme.breakpoints.down('md')}`]: {
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

const AttachmentChip = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    borderRadius: '20px',
    color: theme.palette.secondary.main,
    padding: '0.5rem',
    cursor: 'pointer',
    fontSize: '1rem',
    columnGap: '0.1rem',

    [`${theme.breakpoints.up('md')}`]: {
        '&:hover': {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.main,
            transition: 'ease-in 0.5s',
        },
    },

    [`${theme.breakpoints.down('md')}`]: {
        fontSize: '0.75rem',
    },
}));

const styles: Record<string, SxProps<Theme>> = {
    title: (theme) => ({
        color: theme.palette.text.primary,
        fontWeight: 'bold',
        marginRight: '1rem',
    }),
};

export {
    AttachmentsContainer,
    AttachmentsList,
    ChipContainer,
    AttachmentChip,
    styles,
};
