import {FC} from 'react';
import {LessonContent} from './LessonContent/LessonContent';
import {LessonScreenContainer} from './styles';
import {withNavbar} from '../../utils/hoc/withNavbar';
import {withRoleGuard} from '../../utils/hoc/withRoleGuard';

const LessonScreenComponent: FC = () => {
    return (
        <LessonScreenContainer>
            <LessonContent/>
        </LessonScreenContainer>
    );
};

const LessonScreen = withRoleGuard(withNavbar(LessonScreenComponent));

export {LessonScreen};
