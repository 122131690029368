import {FC, useCallback, useMemo} from 'react';
import {LessonsListContainer} from './styles';
import {LessonBrief} from './LessonBrief/LessonBrief';
import {Lesson} from '../../../../services/lessonService';
import prettyMilliseconds from 'pretty-ms';
import {useNavigate} from 'react-router-dom';
import {useUser} from '../../../../context/user/userContext';
import {LESSON_EDIT_ROLES} from '../../../../shared/config';

interface Props {
    lessons: Lesson[];
    onEditClick: (lesson: Lesson) => void;
}

const convertToLessonTime = (duration: number) => (
    prettyMilliseconds(duration, {colonNotation: true}).split('.')[0]
);

const LessonsList: FC<Props> = (props) => {
    const navigate = useNavigate();
    const {user} = useUser();

    const handleLessonClick = useCallback((episode: number) => {
        navigate(`/lessons/episode/${episode}`);
    }, [])

    const canEditLesson = useMemo(() => (
        !!user?.role && LESSON_EDIT_ROLES.some((role) => role === user.role)
    ), [user?.role]);

    return (
        <LessonsListContainer>
            {props.lessons.map((lesson) => (
                <LessonBrief key={lesson._id}
                             episodeNumber={lesson.episode}
                             lessonName={lesson.name}
                             lessonTime={convertToLessonTime(lesson.durationInMs)}
                             editable={canEditLesson}
                             onEditClick={() => props.onEditClick(lesson)}
                             onClick={() => handleLessonClick(lesson.episode)}/>
            ))}
        </LessonsListContainer>
    );
}

export {
    LessonsList,
};
