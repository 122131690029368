import {styled, SxProps, Theme} from '@mui/material';

const NavbarContainer = styled('nav')(({theme}) => ({
    display: 'flex',
    width: '100vw',
    height: '15vh',
    backgroundColor: theme.palette.primary.dark,
    marginBottom: '8vh',
    padding: '0 2rem',
    boxSizing: 'border-box',
    alignItems: 'center',
    direction: 'ltr',
    boxShadow: '0 5px 10px',
    columnGap: '1rem',

    [theme.breakpoints.up('xl')]: {
        height: '10vh',
    },
}));

const LoginButton = styled('div')(({theme}) => ({
    marginLeft: 'auto',
    display: 'flex',
    color: theme.palette.text.primary,
    columnGap: '0.5rem',
    cursor: 'pointer',

    '&:hover': {
        color: theme.palette.secondary.main,
    },
}));

const styles: Record<string, SxProps<Theme>> = {
    hackcodeLogo: {
        fontSize: '5rem',
        cursor: 'pointer',
    },
    navButton: (theme) => ({
        fontSize: '1rem',
        color: theme.palette.text.primary,
        cursor: 'pointer',

        '&:hover': {
            color: theme.palette.secondary.main,
        },
    }),
    activeNavButton: (theme) => ({
        fontSize: '1rem',
        color: theme.palette.secondary.main,
        cursor: 'pointer',
    }),
    loginText: {
        fontSize: '1rem',
    },
}

export {
    NavbarContainer,
    LoginButton,
    styles,
};
