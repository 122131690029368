import {axiosInstance} from './httpService';

interface LessonFile {
    name: string;
    key: string;
    type: string;
}

interface LessonLink {
    name: string;
    url: string;
}

interface Lesson {
    _id: string,
    course: string,
    durationInMs: number,
    name: string,
    episode: number,
    files: LessonFile[];
    links: LessonLink[];
}

class LessonService {
    public getLessons = async (courseName: string) => axiosInstance.get<Lesson[]>(`/lessons/${courseName}`);

    public updateLesson = async (lessonId: string, lessonName: string) => {
        await axiosInstance.put('/lessons', {_id: lessonId, name: lessonName})
    };

    public getLessonFileSignedUrl = async (key: string) => (
        axiosInstance.get<string>(`/lessons/fileUrl?key=${key}`)
    );
}

const LessonServiceInstance = new LessonService();

export {
    LessonServiceInstance as LessonService,
};

export type {
    Lesson,
    LessonLink,
    LessonFile,
};
