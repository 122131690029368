import {styled} from '@mui/material';

const LessonsListContainer = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    rowGap: '0.3rem',
    height: '80vh',
    boxSizing: 'border-box',
    overflowY: 'scroll',
    overflowX: 'hidden',

    [`${theme.breakpoints.down('md')}`]: {
        marginTop: '2vh',
        height: '60vh',
        maxHeight: '60vh',
        '-webkit-overflow-scrolling': 'touch',
    },

    [`${theme.breakpoints.down('md')} and (orientation: landscape)`]: {
        maxHeight: '100vh',
        height: '100vh',
    },
}));

export {
    LessonsListContainer,
};
