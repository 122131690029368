import {ChangeEvent, FC, useState} from 'react';
import {Dialog, Typography} from '@mui/material';
import {EditLessonContainer, styles} from './styles';
import {CommonInput} from '../../../common/CommonInput/CommonInput';
import {CommonButton} from '../../../common/CommonButton/CommonButton';

interface Props {
    isVisible: boolean;
    onClose: () => void;
    lessonName: string;
    onSave: (newName: string) => void;
    isLoading: boolean;

}

const EditLessonModal: FC<Props> = ((props) => {
    const [lessonName, setLessonName] = useState(props.lessonName);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setLessonName(event.target.value);
    };

    return (
        <Dialog open={props.isVisible} sx={styles.modal} onClose={props.onClose}>
            <EditLessonContainer>
                <Typography sx={styles.title} variant={'h6'}>שינוי שם פרק</Typography>

                <CommonInput label={'שם הפרק'}
                             value={lessonName}
                             InputProps={{sx: styles.input}}
                             InputLabelProps={{sx: styles.inputLabel}}
                             onChange={handleChange}/>

                <CommonButton sx={styles.saveButton}
                              isLoading={props.isLoading}
                              onClick={() => props.onSave(lessonName)}>
                    שמירה
                </CommonButton>
            </EditLessonContainer>
        </Dialog>
    );
});

export {
    EditLessonModal,
};
