import {memo, ReactNode, useMemo} from 'react';
import {Typography} from '@mui/material';
import {AttachmentChip, AttachmentsContainer, AttachmentsList, ChipContainer, styles} from './styles';
import LinkIcon from '@mui/icons-material/Link';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import {LessonFile, LessonLink} from '../../../../services/lessonService';

interface Props {
    onFileClick: (key: string) => void;

    links?: LessonLink[];
    files?: LessonFile[];
}

interface AttachmentChipItem {
    id: string;
    name: string;
    onClick: () => void;
    icon: ReactNode;
}

interface AttachmentChipsProps {
    title: string;
    items: AttachmentChipItem[];
}

const AttachmentChips = memo<AttachmentChipsProps>((props) => (
    <ChipContainer>
        <Typography variant={'h5'} sx={styles.title}>{props.title}</Typography>
        <AttachmentsList>
            {
                props.items.map((item) => (
                    <AttachmentChip key={item.id} onClick={item.onClick}>
                        {item.icon}
                        <span>{item.name}</span>
                    </AttachmentChip>
                ))
            }
        </AttachmentsList>
    </ChipContainer>
))

export const LessonAttachments = memo<Props>((props) => {
    const linkItems = useMemo(() => (
        props.links?.map<AttachmentChipItem>((link) => ({
            id: link.url,
            name: link.name,
            icon: <LinkIcon/>,
            onClick: () => window.open(link.url)
        }))
    ), [props.links]);

    const fileItems = useMemo(() => (
        props.files?.map<AttachmentChipItem>((file) => ({
            id: file.key,
            name: file.name,
            icon: <FileOpenIcon/>,
            onClick: () => props.onFileClick(file.key),
        }))
    ), [props.files]);

    return (
        <AttachmentsContainer>
            {!!linkItems?.length && <AttachmentChips title={'קישורים:'} items={linkItems}/>}
            {!!fileItems?.length && <AttachmentChips title={'מסמכים:'} items={fileItems}/>}
        </AttachmentsContainer>
    );
});
