import {FC, useEffect, useState} from 'react';
import {Form, Formik, FormikHelpers} from 'formik';
import {SxProps, Theme, Typography} from '@mui/material';
import {CommonInput} from '../../../common/CommonInput/CommonInput';
import {LoginFormContainer} from '../styles';
import {useUser} from '../../../context/user/userContext';
import * as Yup from 'yup';
import {SchemaOf} from 'yup';
import {UserErrMsg, UserService} from '../../../services/userService';
import {HttpStatusCode} from 'axios';
import {useNavigate} from 'react-router-dom';
import {CommonButton} from '../../../common/CommonButton/CommonButton';

interface LoginFormValues {
    email: string;
    password: string;
}

const LOGIN_INITIAL_VALUES: LoginFormValues = {email: '', password: ''};

const LOGIN_VALIDATION_SCHEMA: SchemaOf<LoginFormValues> = Yup.object().shape({
    email: Yup.string().email(UserErrMsg.EMAIL_ERR_MSG).required(UserErrMsg.EMAIL_ERR_MSG),
    password: Yup.string().required(UserErrMsg.PASSWORD_ERR_MSG),
});

const DEFAULT_ERR_MSG = 'אופס! משהו השתבש, אנא נסה שנית מאוחר יותר';

const STATUS_CODE_TO_ERR_MSG = {
    [HttpStatusCode.Unauthorized]: 'הפרטים שהוזנו שגויים',
    [HttpStatusCode.TooManyRequests]: 'ביצעת יותר מדי נסיונות, אנא המתן',
};

const errMsgStyle: SxProps<Theme> = (theme) => ({
    color: theme.palette.error.main,
    marginTop: '1vh',
    width: '70%',
    textAlign: 'center',
    alignSelf: 'center',
    fontSize: '1rem',
});

const LoginForm: FC = () => {
    const [loginErrMsg, setLoginErrMsg] = useState('');
    const {user, setUser} = useUser();
    const navigate = useNavigate();

    const handleSubmit = async (
        values: LoginFormValues,
        setSubmitting: FormikHelpers<LoginFormValues>['setSubmitting'],
    ) => {
        setLoginErrMsg('');
        setSubmitting(true);

        try {
            const {data: user} = await UserService.login(values);

            setUser(user);
        } catch (err) {
            const errMsg = STATUS_CODE_TO_ERR_MSG[`${err.response?.status}`];
            setLoginErrMsg(errMsg || DEFAULT_ERR_MSG);
        }

        setSubmitting(false);
    };

    useEffect(() => {
        user && navigate('/my-courses');
    }, [user]);

    return (
        <Formik
            initialValues={LOGIN_INITIAL_VALUES}
            validationSchema={LOGIN_VALIDATION_SCHEMA}
            onSubmit={(values, {setSubmitting}) => handleSubmit(values, setSubmitting)}>
            {({values, handleChange, handleBlur, errors, touched, isSubmitting}) => (
                <Form>
                    <LoginFormContainer>
                        <CommonInput label={'מייל'}
                                     name={'email'}
                                     value={values.email}
                                     onChange={handleChange}
                                     onBlur={handleBlur}
                                     error={touched.email && !!errors.email}
                                     helperText={touched.email && errors.email}/>
                        <CommonInput label={'סיסמה'}
                                     name={'password'}
                                     type={'password'}
                                     onChange={handleChange}
                                     onBlur={handleBlur}
                                     sx={{mt: 1}}
                                     error={touched.password && !!errors.password}
                                     helperText={touched.password && errors.password}/>

                        <CommonButton isLoading={isSubmitting}>כניסה</CommonButton>

                        {!!loginErrMsg && <Typography sx={errMsgStyle}>{loginErrMsg}</Typography>}
                    </LoginFormContainer>
                </Form>
            )}
        </Formik>
    );
};

export {LoginForm};
export type {LoginFormValues};
