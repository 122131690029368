import {FC, useEffect} from 'react';
import {ReactComponent as HackcodeLogo} from '../../assets/images/hackode-icon-blue.svg';
import {CardMedia, SvgIcon, SxProps, Theme, Typography} from '@mui/material';
import {LoginForm} from './LoginForm/LoginForm';
import {ImageContainer, LoginCard, LoginContainer, LoginFormWrapper, LogoWrapper} from './styles';
import LoginBackground from '../../assets/images/login-background.jpg';

const titleStyle: SxProps<Theme> = (theme) => ({
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
});

const subtitleStyle: SxProps<Theme> = (theme) => ({
    marginTop: '1vh',
    color: theme.palette.text.primary,
});

const iconStyle: SxProps<Theme> = (theme) => ({
    width: '10rem',
    height: '10rem',

    [`${theme.breakpoints.down('md')}`]: {
        width: '6rem',
        height: '6rem',
    },
});

const LoginScreen: FC = () => {
    useEffect(() => {
        document.title = 'התחברות';
    }, []);

    return (
        <LoginContainer>
            <LoginCard>
                <LogoWrapper>
                    <SvgIcon sx={iconStyle}><HackcodeLogo/></SvgIcon>
                </LogoWrapper>
                <LoginFormWrapper>
                    <Typography sx={titleStyle} variant={'h4'}>
                        ברוכים הבאים!
                    </Typography>
                    <Typography sx={subtitleStyle} variant={'subtitle1'}>
                        התחברו כדי להתחיל
                    </Typography>

                    <LoginForm/>
                </LoginFormWrapper>
            </LoginCard>
            <ImageContainer>
                <CardMedia component={'img'} style={{borderRadius: '8px'}} image={LoginBackground}/>
            </ImageContainer>
        </LoginContainer>
    );
};

export {LoginScreen};
