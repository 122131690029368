import {FC} from 'react';
import {CreateUserFormContainer, FormWrapper, FullNameContainer, InputsContainer, styles} from './styles';
import {FormControl, InputLabel, MenuItem, Select, TextFieldProps, Typography} from '@mui/material';
import {Formik, FormikHelpers} from 'formik';
import {UserRole} from '../../../../context/user/userContext';
import {CommonInput} from '../../../../common/CommonInput/CommonInput';
import {CommonButton} from '../../../../common/CommonButton/CommonButton';
import * as Yup from 'yup';
import {SchemaOf} from 'yup';
import {UserErrMsg, UserService} from '../../../../services/userService';
import {NotificationService} from '../../../../services/notificationService';
import {HttpStatusCode, isAxiosError} from 'axios';

interface CreateUserFormValues {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    role: UserRole;
}

const CREATE_USER_INITIAL_VALUES: CreateUserFormValues = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    role: UserRole.USER,
};

const CREATE_USER_VALIDATION_SCHEMA: SchemaOf<Omit<CreateUserFormValues, 'role'>> = Yup.object().shape({
    email: Yup.string().email(UserErrMsg.EMAIL_ERR_MSG).required(UserErrMsg.EMAIL_ERR_MSG),
    firstName: Yup.string().required(UserErrMsg.FIRST_NAME_ERR_MSG),
    lastName: Yup.string().required(UserErrMsg.LAST_NAME_ERR_MSG),
    phoneNumber: Yup.string().length(10, UserErrMsg.PHONE_NUMBER_ERR_MSG).required(UserErrMsg.PHONE_NUMBER_ERR_MSG),
});

const USER_ROLE_TO_LABEL: Record<UserRole, string> = {
    [UserRole.USER]: 'לקוח',
    [UserRole.MANAGER]: 'מנהל',
    [UserRole.ADMIN]: 'אדמין',
};

type UserInputProps = {
    ltr?: boolean;
} & TextFieldProps;

const CreateUserInput: FC<UserInputProps> = ({ltr, InputProps, ...props}) => (
    <CommonInput variant={'outlined'}
                 InputProps={{sx: styles.inputStyle, dir: ltr ? 'ltr' : 'rtl', endAdornment: InputProps?.endAdornment}}
                 InputLabelProps={{sx: styles.labelStyle}}
                 {...props}/>
);

const CreateUserForm: FC = () => {

    const handleSubmit = async (
        values: CreateUserFormValues,
        setSubmitting: FormikHelpers<CreateUserFormValues>['setSubmitting'],
    ) => {
        setSubmitting(true);

        try {
            await UserService.createUser(values);

            NotificationService.success('המשתמש נשמר בהצלחה');
        } catch (err) {
            NotificationService.error(
                isAxiosError(err) && err.response?.status === HttpStatusCode.Conflict ?
                    'מייל זה כבר קיים במערכת' :
                    'אופס! משהו השתבש',
            );
        }

        setSubmitting(false);
    };

    return (
        <CreateUserFormContainer>
            <FormWrapper>
                <div>
                    <Typography sx={styles.titleStyle} variant={'h4'}>יצירת משתמש</Typography>
                </div>

                <Formik initialValues={CREATE_USER_INITIAL_VALUES}
                        validationSchema={CREATE_USER_VALIDATION_SCHEMA}
                        onSubmit={(values, {setSubmitting}) => handleSubmit(values, setSubmitting)}>
                    {({
                          values,
                          handleChange,
                          handleBlur,
                          errors,
                          touched,
                          isValid,
                          dirty,
                          submitForm,
                          isSubmitting,
                      }) => (
                        <>
                            <FullNameContainer>
                                <Typography sx={styles.subtitleStyle} variant={'subtitle1'}>שם מלא</Typography>
                                <InputsContainer>
                                    <CreateUserInput name={'firstName'}
                                                     label={'שם פרטי'}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     onBlur={handleBlur}
                                                     error={touched.firstName && !!errors.firstName}
                                                     helperText={touched.firstName && errors.firstName}
                                                     sx={styles.rowedInput}/>
                                    <CreateUserInput name={'lastName'}
                                                     label={'שם משפחה'}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     onBlur={handleBlur}
                                                     error={touched.lastName && !!errors.lastName}
                                                     helperText={touched.lastName && errors.lastName}
                                                     sx={styles.rowedInput}/>
                                </InputsContainer>
                            </FullNameContainer>

                            <CreateUserInput ltr
                                             name={'email'}
                                             label={'מייל'}
                                             value={values.email}
                                             onChange={handleChange}
                                             onBlur={handleBlur}
                                             error={touched.email && !!errors.email}
                                             helperText={touched.email && errors.email}/>

                            <InputsContainer>
                                <FormControl sx={styles.selectFormControlStyle}>
                                    <InputLabel variant={'outlined'} sx={styles.labelStyle}>סוג משתמש</InputLabel>
                                    <Select name={'role'}
                                            value={values.role}
                                            onChange={handleChange}
                                            sx={styles.selectStyle}
                                            MenuProps={{
                                                anchorOrigin: {vertical: 65, horizontal: 'center'},
                                                MenuListProps: {sx: styles.menuListStyle},
                                            }}>
                                        {
                                            Object.values(UserRole).map((role) => (
                                                    <MenuItem key={role} value={role} sx={styles.menuItemStyle} divider>
                                                        <Typography sx={styles.menuItemText}>
                                                            {USER_ROLE_TO_LABEL[role] ? USER_ROLE_TO_LABEL[role] : ''}
                                                        </Typography>
                                                    </MenuItem>
                                                )
                                            )
                                        }
                                    </Select>
                                </FormControl>
                                <CreateUserInput ltr
                                                 name={'phoneNumber'}
                                                 label={'טלפון'}
                                                 value={values.phoneNumber}
                                                 onChange={handleChange}
                                                 onBlur={handleBlur}
                                                 error={touched.phoneNumber && !!errors.phoneNumber}
                                                 helperText={touched.phoneNumber && errors.phoneNumber}
                                                 sx={styles.rowedInput}/>
                            </InputsContainer>

                            <CommonButton onClick={submitForm}
                                          isLoading={isSubmitting}
                                          disabled={!isValid || !dirty || isSubmitting}>יצירה</CommonButton>
                        </>
                    )}
                </Formik>
            </FormWrapper>
        </CreateUserFormContainer>
    );
};

export {CreateUserForm};

export type {CreateUserFormValues};
