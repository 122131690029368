import './App.css';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {ROUTES} from './shared/routes';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import {CacheProvider} from '@emotion/react';
import {UserProvider} from './context/user/userContext';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {styled} from '@mui/material';

const router = createBrowserRouter(ROUTES);

const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [rtlPlugin as never],
});

export const theme = createTheme({
    palette: {
        primary: {
            main: '#252525',
            dark: '#0F0E0E',
        },
        secondary: {
            main: '#00b0b2',
            dark: '#393E46',
        },
        text: {
            primary: '#FFFFFF',
        },
        background: {
            paper: '#EEEEEE',
        },
    },
    typography: {
        fontFamily: 'Heebo',
    },
    direction: 'rtl',
});

const Background = styled('div')(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
    minHeight: '100vh',
    minWidth: '100vw',
}));

function App() {
    return (
        <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={theme}>
                <Background>
                    <UserProvider>
                        <RouterProvider router={router}/>
                    </UserProvider>
                    <ToastContainer/>
                </Background>
            </ThemeProvider>
        </CacheProvider>
    );
}

export default App;
