import axios, {HttpStatusCode, isAxiosError} from 'axios';
import {USER_LOCAL_STORAGE_KEY} from './userService';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    withCredentials: true,
    timeout: 20 * 1000,
});

axiosInstance.interceptors.response.use(res => res, (err) => {
    if (isAxiosError(err) && err.response) {
        const {response} = err;

        if (response.status === HttpStatusCode.Forbidden) {
            window.location.href = '/my-courses';
        } else if (response.status === HttpStatusCode.Unauthorized && window.location.pathname !== '/login') {
            localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
            window.location.href = '/login';
        } else {
            throw err;
        }
    }

    throw err;
});

export {
    axiosInstance,
};
