import {memo, useEffect, useRef} from 'react';
import {Video, VideoContainer} from './styles';

interface Props {
    episode: number;
}

const LessonVideo = memo<Props>((props) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        videoRef?.current?.load();
    }, [props.episode]);

    return (
        <VideoContainer>
            <Video controls
                   playsInline
                   crossOrigin={'anonymous'}
                   ref={videoRef}
                   controlsList={'nodownload'}
                   preload={'metadata'}>
                {
                    props.episode &&
                    <source src={`${process.env.REACT_APP_SERVER_URL}/lessons/video/javascript/${props.episode}`}
                            type="video/mp4"/>
                }
            </Video>
        </VideoContainer>
    );
});

export {
    LessonVideo,
};
