import {FC} from 'react';
import {Navbar} from '../../common/Navbar/Navbar';

const withNavbar = <P extends object>(Component: FC<P>) => (props: P) => (
    <>
        <Navbar/>
        <Component {...props}/>
    </>
);

export {
    withNavbar,
};
