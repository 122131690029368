import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {LessonContentContainer, VideoAndAttachmentsContainer, VideoAndLessonsContainer} from './styles';
import {LessonsList} from './LessonsList/LessonsList';
import {LessonVideo} from './LessonVideo/LessonVideo';
import {Lesson, LessonService} from '../../../services/lessonService';
import {useNavigate, useParams} from 'react-router-dom';
import {EditLessonModal} from '../EditLessonModal/EditLessonModal';
import {NotificationService} from '../../../services/notificationService';
import {LessonAttachments} from './LessonAttachments/LessonAttachments';
import {LessonHeader} from './LessonHeader/LessonHeader';

const isEpisodeParamValid = (param: undefined | string): param is string => !!param && !!+(param);

const downloadFile = (fileKey: string) => {
    LessonService.getLessonFileSignedUrl(fileKey).then(({data: signedUrl}) => {
        window.location.assign(signedUrl);
    });
};

const LessonContent: FC = () => {
    const {episode} = useParams();
    const navigate = useNavigate();
    const [lessons, setLessons] = useState<Lesson[]>([]);
    const [editedLesson, setEditedLesson] = useState<Lesson>();
    const [isLoadingEdit, setIsLoadingEdit] = useState(false);

    useEffect(() => {
        document.title = 'Javascript';
    }, []);

    useEffect(() => {
        if (!isEpisodeParamValid(episode)) {
            navigate('/lessons/episode/1');
        } else {
            document.title = 'Javascript - פרק ' + episode;
        }
    }, [episode]);

    useEffect(() => {
        LessonService.getLessons('javascript').then(({data: lessons}) => {
            setLessons(lessons);
        });
    }, []);

    const handleNext = useCallback(() => {
        !!isEpisodeParamValid(episode) && navigate(`/lessons/episode/${+episode + 1}`);
    }, [episode]);

    const handlePrev = useCallback(() => {
        !!isEpisodeParamValid(episode) && navigate(`/lessons/episode/${+episode - 1}`);
    }, [episode]);

    const handleOnEdit = useCallback((lessonName?: string) => {
        setIsLoadingEdit(true);

        if (editedLesson?._id) {
            LessonService.updateLesson(editedLesson._id, lessonName ?? '')
                .then(() => {
                    NotificationService.success('שם הפרק עודכן בהצלחה!');
                    setLessons((currLessons) => {
                        const lessons = [...currLessons];
                        const lessonIndex = lessons.findIndex((lesson) => lesson._id === editedLesson._id);
                        lessons[lessonIndex].name = lessonName ?? '';

                        return lessons;
                    });

                    setEditedLesson(undefined);
                }).catch(() => NotificationService.error('אופס! משהו השתבש'))
                .finally(() => {
                    setIsLoadingEdit(false);
                });
        }
    }, [editedLesson?._id]);

    const currentLesson = useMemo(() => (
        episode ? lessons.find((lesson) => lesson.episode === +episode) : null
    ), [lessons, episode]);

    return (
        <LessonContentContainer>
            {
                !!isEpisodeParamValid(episode) &&
                <>
                    <LessonHeader isNextDisabled={+episode == lessons.length}
                                  isPrevDisabled={+episode == 1}
                                  onNext={handleNext}
                                  onPrev={handlePrev}/>

                    <VideoAndLessonsContainer>
                        <VideoAndAttachmentsContainer>
                            <LessonVideo episode={+episode}/>
                            <LessonAttachments onFileClick={downloadFile}
                                               files={currentLesson?.files}
                                               links={currentLesson?.links}/>
                        </VideoAndAttachmentsContainer>
                        <LessonsList lessons={lessons} onEditClick={setEditedLesson}/>
                        {
                            !!editedLesson &&
                            <EditLessonModal isVisible
                                             lessonName={editedLesson.name}
                                             isLoading={isLoadingEdit}
                                             onSave={handleOnEdit}
                                             onClose={() => setEditedLesson(undefined)}/>
                        }
                    </VideoAndLessonsContainer>
                </>
            }
        </LessonContentContainer>
    );
};

export {
    LessonContent,
};
