import {styled, SxProps, Theme} from '@mui/material';

const LessonHeaderContainer = styled('div')(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: '0.8rem',
    width: '65vw',

    [`${theme.breakpoints.down('md')}`]: {
        width: '100%',
    },

    [`${theme.breakpoints.down('md')}  and (orientation: landscape)`]: {
        width: '100%',
    },
}));

const styles: Record<string, SxProps<Theme>> = {
    videoButton: (theme) => ({
        columnGap: '0.2rem',
        fontSize: '1rem',
        marginTop: 0,
        verticalAlign: 'middle',
        lineHeight: 1,
        width: '20vw',

        [`${theme.breakpoints.down('md')}`]: {
            fontSize: '0.7rem',
            flex: 1,
        },
    }),
    buttonIcon: (theme) => ({
        fontSize: '1rem',
        verticalAlign: 'middle',
        lineHeight: 1,

        [`${theme.breakpoints.down('md')}`]: {
            fontSize: '0.8rem',
        },
    }),
    title: (theme) => ({
        textAlign: 'center',
        color: theme.palette.text.primary,
        lineHeight: 1,
        fontWeight: 'bold',

        [`${theme.breakpoints.down('md')}`]: {
            fontSize: '1.5rem',
        },
    }),
};

export {
    LessonHeaderContainer,
    styles,
};
