import {styled} from '@mui/material';

const LoginContainer = styled('main')({
    display: 'flex',
    minHeight: '100vh',
    direction: 'ltr',
});

const LoginCard = styled('section')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',

    [`${theme.breakpoints.down('md')}`]: {
        margin: '5vh',
        justifyContent: 'flex-start',
    },
}));

const LogoWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'center',
});

const LoginFormWrapper = styled('div')(({theme}) => ({
    width: '100%',
    padding: '0 4vw',
    boxSizing: 'border-box',

    [`${theme.breakpoints.down('md')}`]: {
        marginTop: '3vh 0',
        padding: '0 14vw',
        textAlign: 'center',
    },
}));

const LoginFormContainer = styled('div')({
    flexDirection: 'column',
    marginTop: '2vh',
    display: 'flex',
    rowGap: '1vh',
});

const ImageContainer = styled('section')(({theme}) => ({
    display: 'flex',
    flex: 2,
    padding: '2vh',

    [`${theme.breakpoints.down('md')}`]: {
        display: 'none',
    },
}));

export {
    LoginContainer,
    LoginCard,
    LogoWrapper,
    LoginFormWrapper,
    LoginFormContainer,
    ImageContainer,
};
