import {styled} from '@mui/material';

const LessonScreenContainer = styled('main')({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
});

export {
    LessonScreenContainer,
};
