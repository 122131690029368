import {styled} from '@mui/material';

const CreateUserContainer = styled('section')(({theme}) => ({
    display: 'flex',
    padding: '3vh 3vw',
    height: '100vh',
    flex: 1,

    [`${theme.breakpoints.down('md')}`]: {
        flexDirection: 'column',
    },
}));

const ImageContainer = styled('div')(({theme}) => ({
    backgroundColor: theme.palette.background.paper,
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',

    [`${theme.breakpoints.down('md')}`]: {
        display: 'none',
    },
}));

export {
    CreateUserContainer,
    ImageContainer,
};
