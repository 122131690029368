import {FC, useEffect, useState} from 'react';
import {CourseCard, MyCoursesContainer, MyCoursesList, styles} from './styles';
import {CardMedia, Typography} from '@mui/material';
import {axiosInstance} from '../../services/httpService';
import {NotificationService} from '../../services/notificationService';
import {useNavigate} from 'react-router-dom';
import {withNavbar} from '../../utils/hoc/withNavbar';
import {withRoleGuard} from '../../utils/hoc/withRoleGuard';

interface Course {
    _id: string;
    name: string;
    imageBase64: string;
}

const BASE_64_PREFIX = 'data:image/jpg;base64,';

const MyCoursesScreenComponent: FC = () => {
    const [courses, setCourses] = useState<Course[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'הקורסים שלי';
    }, []);

    useEffect(() => {
        axiosInstance.get('/courses').then(({data}) => {
            setCourses(data);
        }).catch(err => NotificationService.error(err.message));
    }, []);

    const handleOnCourseClick = () => {
        navigate('/lessons');
    };

    return (
        <MyCoursesContainer>
            <Typography variant={'h3'} sx={styles.title}>הקורסים שלי</Typography>

            <MyCoursesList>
                {
                    courses.map(({_id, name, imageBase64}) => (
                        <CourseCard key={_id} onClick={handleOnCourseClick}>
                            <CardMedia component={'img'}
                                       sx={styles.image}
                                       image={`${BASE_64_PREFIX}${imageBase64}`}/>
                            <Typography variant={'h5'} sx={styles.courseName}>{name}</Typography>
                        </CourseCard>
                    ))
                }
            </MyCoursesList>
        </MyCoursesContainer>
    );
};

const MyCoursesScreen = withRoleGuard(withNavbar(MyCoursesScreenComponent));

export {MyCoursesScreen};
