import {LessonBriefContainer, LessonBriefContentContainer, LessonBriefHover, styles, TimeContainer} from './styles';
import {memo} from 'react';
import {Typography} from '@mui/material';
import ClockIcon from '@mui/icons-material/AccessTimeFilled';
import PlayIcon from '@mui/icons-material/PlayCircleOutlined';
import EditIcon from '@mui/icons-material/ModeEditOutline';

interface Props {
    onClick: () => void;
    episodeNumber: number;
    lessonName: string;
    lessonTime: string;
    editable: boolean;
    onEditClick: () => void;
}

const LessonBrief = memo<Props>((props) => (
    <LessonBriefContainer>
        <LessonBriefHover>
            <LessonBriefContentContainer onClick={props.onClick}>
                <Typography sx={styles.lessonName} title={props.lessonName}>
                    {`${props.episodeNumber}. ${props.lessonName}`}
                </Typography>

                <TimeContainer>
                    <ClockIcon sx={styles.clockIcon}/>
                    <Typography sx={styles.lessonTime}>{props.lessonTime}</Typography>
                </TimeContainer>
            </LessonBriefContentContainer>

            <PlayIcon sx={styles.playIcon}/>
        </LessonBriefHover>
        {props.editable && <EditIcon sx={styles.editIcon} onClick={props.onEditClick}/>}
        {/*todo: add checkbox logic in client and server*/}
        {/*<Checkbox sx={styles.checkbox}/>*/}
    </LessonBriefContainer>
));

export {LessonBrief};
