import {FC, useEffect} from 'react';
import {styled} from '@mui/material';
import {CreateUser} from './CreateUser/CreateUser';
import {withNavbar} from '../../utils/hoc/withNavbar';
import {withRoleGuard} from '../../utils/hoc/withRoleGuard';
import {UserRole} from '../../context/user/userContext';

const UserManagementContainer = styled('main')(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    minHeight: '100vh',
}));

const UserManagementComponent: FC = () => {
    useEffect(() => {
        document.title = 'ניהול משתמשים';
    }, []);

    return (
        <UserManagementContainer>
            <CreateUser/>
        </UserManagementContainer>
    );
};

const UserManagement = withRoleGuard(withNavbar(UserManagementComponent), [UserRole.MANAGER, UserRole.ADMIN]);

export {UserManagement};
