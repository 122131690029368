import {createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useEffect, useState} from 'react';
import {UserService} from '../../services/userService';

enum UserRole {
    USER = 'user',
    MANAGER = 'manager',
    ADMIN = 'admin',
}

interface User {
    firstName: string;
    lastName: string;
    role: UserRole;
}

interface UserContext {
    user?: User;
    setUser: Dispatch<SetStateAction<User | undefined>>;
}

interface UserProviderProps {
    children: ReactNode;
}

const UserContext = createContext<UserContext | undefined>(undefined);

const UserProvider: FC<UserProviderProps> = ({children}) => {
    const [user, setUser] = useState<User>();

    useEffect(() => {
        user && UserService.saveUserInLocalStorage(user);
    }, [user]);

    return (
        <UserContext.Provider value={{user, setUser}}>
            {children}
        </UserContext.Provider>
    );
};

const useUser = () => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider');
    }

    return context;
};

export {
    UserRole,
    UserProvider,
    useUser,
};

export type {User};
