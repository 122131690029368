import {memo} from 'react';
import {CreateUserForm} from './CreateUserForm/CreateUserForm';
import {CreateUserContainer, ImageContainer} from './styles';
import {SvgIcon, SxProps, Theme} from '@mui/material';
import {ReactComponent as HackcodeLogo} from '../../../assets/images/hackode-icon-black.svg';

const iconStyle: SxProps<Theme> = (theme) => ({
    width: '70%',
    height: '70%',

    [`${theme.breakpoints.down('md')}`]: {
        width: '6rem',
        height: '6rem',
    },
});

const CreateUser = memo(() => (
    <CreateUserContainer>
        <CreateUserForm/>

        <ImageContainer>
            <SvgIcon sx={iconStyle}><HackcodeLogo/></SvgIcon>
        </ImageContainer>
    </CreateUserContainer>
));

export {CreateUser};
