import {toast, ToastOptions} from 'react-toastify';

export class NotificationService {
    private static readonly _options: ToastOptions = {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: 'colored',
    };

    public static success = (message: string) => {
        toast.success(message, NotificationService._options);
    };

    public static error = (message: string) => {
        toast.error(message, NotificationService._options);
    };
}
